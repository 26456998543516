import { mapGetters } from "vuex";
import JointUseGridAPI from "../components/dynamic/JointUse/LocalAPI/JointUseGridAPI";
import Axios from "axios";
import { HubConnectionBuilder } from "@microsoft/signalr";

export default {
  data() {
    return {
      connection: null
    };
  },
  // Register your listener here.
  // sockets: {
  //   ReceiveJUNotification(data) {
  //     const existingNotification =
  //       this.$store.state.NotificationStore.notifications;
  //     if (existingNotification.filter(a => a.id == data.id).length == 0) {
  //       data.notificationModule = "jointuse";
  //       // data.clickCallback = () => {
  //       //   console.log(
  //       //     "JU click action in notification manager: routeName is: ",
  //       //     this.$route.name,
  //       //     ", notificationModule is: ",
  //       //     data.notificationModule,
  //       //     ", currentProjectId is: ",
  //       //     this.$store.getters.currentProjectId,
  //       //     ", notificationProjectid is: ",
  //       //     data.projectId
  //       //   );

  //       //   if (
  //       //     this.$route.name === data.notificationModule &&
  //       //     this.$store.getters.currentProjectId == data.projectId
  //       //   ) {
  //       //     this.$store.commit("SET_JU_NOTIFICATIONS_STATUS", true);
  //       //   } else {
  //       //     this.$store.commit("SET_JUNOTIFICATION_FROM_OUTSIDE_JU", true);
  //       //   }
  //       // };
  //       existingNotification.push(data);
  //       if (this.$store.getters.getDownloadManagerPopup) {
  //         const notifications =
  //           this.$store.state.NotificationStore.JUReceivedNotification;
  //         notifications.unshift(data);
  //         this.$store.dispatch("setJUReceivedNotificationdata", notifications);
  //       }
  //     }
  //     this.$store.dispatch("setNotificationData", existingNotification);
  //   },
  //   ReceiveGenericNotification(data) {
  //     const existingNotification =
  //       this.$store.state.NotificationStore.notifications;
  //     // ignore duplicate notifications
  //     if (existingNotification.filter(a => a.id == data.id).length) return;

  //     if (data.type === "O360EXPORT") {
  //       data = this.$store.getters.formatO360ExportNotification(data);
  //     } else if (data.type === "JU") {
  //       data = this.$store.getters.formatJUImportNotification(data);
  //     } else if (data.type == "OsmoViewPDF") {
  //       data = this.$store.getters.formatOsmoVisionPDFNotification(data);
  //     } else {
  //       // data.notificationModule = "generic";
  //       data.isGeneric = true;
  //     }

  //     existingNotification.unshift(data);

  //     this.$store.dispatch("setNotificationData", existingNotification);
  //   }
  // },
  watch: {
    userAuthObject(newVal, oldVal) {
      if (!oldVal && newVal) {
        if (this.hasJUCapabilities && this.hasJUCapabilities.length > 0) {
          JointUseGridAPI.getDownloadedRequest(
            this.getDownloadRequestUrl,
            this.$store.getters.authToken,
            "notification",
            "jointuse",
            "",
            true
          );
        }
        this.$store.dispatch("getMVUnReadExportNotifications");
        // the following method now gets mv export notifications as well as JU import notifications
        // if (this.hasMVCapabilities?.length || this.hasJUCapabilities?.length) {
        //   this.$store.dispatch("getMVUnReadExportNotifications");
        // }

        const negotiationUrl = process.env.VUE_APP_NOTIFICATION_NEGOTIATE;

        Axios.post(negotiationUrl, "", {
          headers: { Authorization: `Bearer ${this.$store.getters.authToken}` }
        })
          .then(response => {
            const hub = response.data.Url;
            const accessToken = response.data.AccessToken;

            this.connection = new HubConnectionBuilder()
              .withUrl(hub, {
                accessTokenFactory: () => accessToken
              })
              .build();

            this.connection
              .start()
              .then(() => {
                console.log("SignalR connection established!");
              })
              .catch(err => {
                console.error("Error establishing SignalR connection:", err);
              });

            this.connection.on("RecieveMessagesJU", data => {
              console.log("RecieveMessagesJU");
              console.log(data);

              const existingNotification =
                this.$store.state.NotificationStore.notifications;

              if (
                existingNotification.filter(a => a.id == data.Id).length == 0
              ) {
                existingNotification.push(data);
                if (this.$store.getters.getDownloadManagerPopup) {
                  const notifications =
                    this.$store.state.NotificationStore.JUReceivedNotification;
                  notifications.unshift(data);
                  this.$store.dispatch(
                    "setJUReceivedNotificationdata",
                    notifications
                  );
                }
              }
              this.$store.dispatch("setNotificationData", existingNotification);
            });

            this.connection.on("RecieveMessagesGeneric", data => {
              console.log("generic messages");
              console.log(data);

              const existingNotification =
                this.$store.state.NotificationStore.notifications;
              // ignore duplicate notifications
              if (existingNotification.filter(a => a.id == data.Id).length)
                return;

              if (data.Type === "O360EXPORT") {
                data = this.$store.getters.formatO360ExportNotification(data);
              } else if (data.Type === "JU") {
                data = this.$store.getters.formatJUImportNotification(data);
              } else if (
                data.Type == "OsmoViewPDF" ||
                data.Type == "OsmoViewCSV"
              ) {
                data =
                  this.$store.getters.formatOsmoVisionPDFNotification(data);
              } else {
                // data.notificationModule = "generic";
                data.isGeneric = true;
              }

              existingNotification.unshift(data);

              this.$store.dispatch("setNotificationData", existingNotification);
            });
          })
          .catch(error => {
            console.log("Error getting negotiation details");
          });
      }
    },
    computed: {
      ...mapGetters(["authToken"]),
      getDownloadRequestUrl() {
        return process.env.VUE_APP_JOINT_USE_GET_DOWNLOAD_REQUEST;
      },
      userAuthObject() {
        return this.$store.getters.userAuthObject;
      },
      hasJUCapabilities() {
        return this.$store.getters.capabilities("JointUse");
      },
      hasMVCapabilities() {
        return this.$store.getters.capabilities("MapView");
      }
    },
    unmounted() {
      if (this.connection) {
        connection
          .stop()
          .then(() => {
            console.log("SignalR connection closed");
          })
          .catch(err => console.error(err.toString()));
      }
      //this.$socket.socket.stop();
    }
  }
};
